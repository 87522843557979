import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from "@material-ui/core";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'moment/locale/fr';
import moment from 'moment';

import RoutineScreen from "./RoutineScreen";
import "../CSS/ListRoutineScreen.css";
import { connect } from 'react-redux';

import { getRoutines } from '../../../../actions/routineActions';

//new date picker
import TextField from "@material-ui/core/TextField";

import {
    DateRangePicker,
    MobileDateRangePicker,
    DateRangeDelimiter,
    LocalizationProvider,
} from "@material-ui/pickers";

import MomentAdapter from "@material-ui/pickers/adapter/moment";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"
import fr from "date-fns/locale/fr";
//buttons
import Button from '@material-ui/core/Button';

//select
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//theme
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { lightBlue } from "@material-ui/core/colors";

const ListRoutineScreen = ({ setRoutine, getRoutines, openBackDrop, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const routines = useSelector((state) => state.routines);
    const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'

    //toogle 
    const [showDRP, setShowDRP] = useState(false);

    moment.locale('fr');

    //removebackdrop
    useEffect(() => {
        setOpenBackdrop(false);
    }, [routines])

    //set backdrop
    useEffect(() => {
        setOpenBackdrop(true);
        //setOpenBackdrop(true);
    }, [])


    return (

        <div className="todo__content grid">

            <div>
                <div className="todo__content--header">
                    <div className="todo__content--headerLeft">

                        <span className="todo__content--headerSubtitle">{routines.length > 0 ? "Votre routine" : "Vous n'avez pas encore de routine "}</span>
                    </div>
                </div>
            </div>

            <div className="todo__list">
                {routines && routines.map((routine) => {
                    return (
                        <>
                            <RoutineScreen
                                routine={routine}
                                key={routine._id}
                                setRoutine={setRoutine}
                                setOpenBackdrop={setOpenBackdrop}
                                setIsAddComponent={setIsAddComponent}
                                setOpenConfirmation={setOpenConfirmation}
                                setIdToDelete={setIdToDelete}
                            />
                        </>);
                })}
            </div>
        </div >
    )
}

export default connect(null, { getRoutines })(ListRoutineScreen);