import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import "../CSS/RegisterScreen.css";

import { connect } from 'react-redux';
import { register } from '../../../../actions/authActions';

import logoLg from '../../../../img/logoLg.png'

const RegisterScreen = ({ register }) => {
    const auth = useSelector(state => state.auth.user);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const emailParams = params.get('email');

    const [confirmpassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [user, setUser] = useState({
        name: "",
        email: emailParams,
        password: ""
    });

    const registerHandler = async (e) => {
        e.preventDefault();

        if (user.password !== confirmpassword) {

            setUser({ ...user, password: "" });
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords do not match");
        }

        setUser({
            name: user.name,
            email: user.email,
            password: user.password
        })

        register(user);

        setUser({
            name: "",
            email: "",
            password: ""
        })

        setConfirmPassword("")
    };

    return (
        <>
            {auth.token ? (
                <Redirect to="/login" />
            ) : (
                <div className="welcome">
                    <form onSubmit={registerHandler} className="register-screen__form">

                        <img src={logoLg} alt="" className="login__img" />
                        <h3 className="register-screen__title">S'enregister</h3>
                        {error && <span className="error-message">{error}</span>}

                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                disabled
                                type="email"
                                required
                                id="email"
                                placeholder="Entrez votre adresse mail"
                                value={emailParams}
                            //onChange={(e) => setUser({ ...user, email: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Nom:</label>
                            <input
                                type="text"
                                required
                                id="name"
                                placeholder="Entrez votre nom"
                                value={user.name}
                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Mot de passe:</label>
                            <input
                                type="password"
                                required
                                id="password"
                                autoComplete="true"
                                placeholder="Entez votre mot de passe"
                                value={user.password}
                                onChange={(e) => setUser({ ...user, password: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirmpassword">Confirmez votre mot de passe:</label>
                            <input
                                type="password"
                                required
                                id="confirmpassword"
                                autoComplete="true"
                                placeholder="Confirmez votre mot de passe"
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>

                        <button type="submit" className="button button__submit">
                            S'enregister
                        </button>

                        <span className="register-screen__subtext">
                            Vous avez déjà un compte? <Link to="/login">Se connecter</Link>
                        </span>
                    </form>
                </div>
            )}
        </>
    );
};

export default connect(null, { register })(RegisterScreen);