import axios from 'axios';
import { toast } from 'react-toastify'
import { setHeaders } from '../api/headers';

// Login
export const login = (credentials) => async (dispatch) => {
    await axios.post(`/api/auth/login`, credentials)
        .then(token => {
            dispatch({
                type: "LOGIN",
                user: {
                    token: token.data.token,
                    user: token.data.user,
                    dates: {
                        start: token.data.user.startDayChallenge,
                        end: token.data.user.endDayChallenge,
                        trimesters: token.data.user.trimesters,
                    }
                }
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Register
export const register = (user) => async (dispatch) => {
    axios.post('/api/auth/register', user)
        .then(token => {
            dispatch({
                type: "REGISTER",
                user: {
                    token: token.data.token,
                    user: token.data.user,
                    dates: {
                        start: token.data.user.startDayChallenge,
                        end: token.data.user.endDayChallenge,
                        trimesters: token.data.user.trimesters,
                    }
                }
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Logout
export const logout = () => {
    return (dispatch) => {
        dispatch({
            type: "LOGOUT",
        })
    }
}

// Number step intro
export const numberStepIntro = (updatedStepNumber) => (dispatch, getState) => {
    const token = getState().auth.user.token;
    axios.put(`/api/auth/numberstepintro`, updatedStepNumber, setHeaders(token))
        .then(user => {
            dispatch({
                type: "UPDATE_STEP_NUMBER_INTRO",
                user,
                token: user.data.token
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Update user start day challenge / trimester dates
export const startChallenge = (startChallenge, id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/auth/startdaychallenge/${id}`, { start: startChallenge }, setHeaders(token))
        .then(user => {
            dispatch({
                type: "START_CHALLENGE",
                user,
                token: user.data.token
            })
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Restart challenge
export const restartChallenge = (history) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/auth/restart`, setHeaders(token))
        .then(user => {
            dispatch({
                type: "RESTART_CHALLENGE",
                user,
                token: user.data.token
            })

            history.push("/hello");
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Restart challenge
export const restartAfterFinishChallenge = (history) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/auth/newchallenge`, setHeaders(token))
        .then(user => {
            dispatch({
                type: "RESTART_AFTER_FINISH_CHALLENGE",
                user,
                token: user.data.token
            })

            history.push("/");
        })
        .catch(error => {
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}
