import { toast } from 'react-toastify';

const initialState = {
    points: {
        yearlyTotalEstimatedPercentage: "",
        yearlyTotalRealisedPercentage: "",
        yearlyTotalPoints: "",
        trimesterTotalEstimatedPercentage: "",
        trimesterRealisedPercentage: "",
        trimesterTotalPoints: "",
        weeklyTotalEstimatedPercentage: "",
        weeklyTotalRealisedPercentage: "",
        weeklyTotalPoints: "",
        dailyTotalPoints: "",
        totalPoints: "",
        usedPoints: "",
        todos: [],
    }
}

const pointReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_POINTS":
            return {
                ...state,
                points: {
                    yearlyTotalEstimatedPercentage: action.points.data.points.yearlyTotalEstimatedPercentage,
                    yearlyTotalRealisedPercentage: action.points.data.points.yearlyTotalRealisedPercentage,
                    yearlyTotalPoints: action.points.data.points.yearlyTotalPoints,
                    trimesterTotalEstimatedPercentage: action.points.data.points.trimesterTotalEstimatedPercentage,
                    trimesterRealisedPercentage: action.points.data.points.trimesterRealisedPercentage,
                    trimesterTotalPoints: action.points.data.points.trimesterTotalPoints,
                    weeklyTotalEstimatedPercentage: action.points.data.points.weeklyTotalEstimatedPercentage,
                    weeklyTotalRealisedPercentage: action.points.data.points.weeklyTotalRealisedPercentage,
                    weeklyTotalPoints: action.points.data.points.weeklyTotalPoints,
                    dailyTotalPoints: action.points.data.points.dailyTotalPoints,
                    totalPoints: action.points.data.points.totalPoints,
                    usedPoints: action.points.data.points.usedPoints,
                    todos: action.points.data.points.todos,
                }
            };
        default:
            return state
    }
}

export default pointReducer;