import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Button, TextField } from "@material-ui/core";
import { Send } from '@material-ui/icons';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//stepper
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import "./NotYetStarted.css";

import { startChallenge, numberStepIntro } from "../../../actions/authActions";
import ConfirmationModal from '../../modal/ConfirmationModal';

//translation
import { useTranslation } from "react-i18next";

//action
import { restartAfterFinishChallenge } from "../../../actions/authActions";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Finish({ restartAfterFinishChallenge }) {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [openBackDrop, setOpenBackdrop] = useState(false);

    const { t, i18n } = useTranslation("");
    const classes = useStyles();

    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
    };

    const auth = useSelector((state) => state.auth.user);

    const restartAfterFinishChallengeHandler = () => {
        restartAfterFinishChallenge();
    }

    const changeHandler = () => {
        setOpenConfirmation(true)
    }

    return (
        <div className="welcome">

            <Backdrop className={classes.backdrop} open={openBackDrop} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ConfirmationModal
                openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
                confirmationTitle="Changer le début du challenge"
                confirmationText="Etes-vous sur de vouloir recommencer le challenge?"
                component="Restart"
                idToDelete={idToDelete}
                setOpenBackdrop={setOpenBackdrop}
            />

            <div className={classes.root}>
                <div>
                    <h2 className="section__title">Votre challenge commence bientôt !</h2>
                    <br></br>
                    <span className="section__subtitle">
                        <p>
                            Votre challenge comment le {auth.startDayChallenge}.
                        </p><br></br>
                        <p>
                            Les différentes sections seront débloquées une fois que votre challenge démarre.
                        </p>
                        <p>
                            Vous pouvez toujours changer la date du début de votre challange en cliquant sur le bouton ci-dessous
                        </p>
                        <div className="btn--intro">
                            <Button
                                className={classes.backButton}
                                variant="contained"
                                color="primary"
                                onClick={changeHandler}
                            >
                                Changer la date de début de mon challenge
                            </Button>
                        </div>
                        <br></br>
                        <p>
                            Amicalement :)
                        </p><br></br>
                        <p>
                            Jimmy
                        </p>
                    </span>
                </div>
            </div>
        </div >
    );
}

export default connect(null, { restartAfterFinishChallenge })(Finish);
