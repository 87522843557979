import React from "react";

function MyAddbutton({ propsAdd }) {
  return (
    <>
      <span className={`modal__button button__add ${propsAdd}  `}>
        <i className="uil uil-plus"> </i>
      </span>
    </>
  );
}

export default MyAddbutton;
