import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Recompense from "./Recompense";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    display: "flex",
    justifyContent: "center",
  },
}));

const dummyData = [
  {
    id: 1,
    name: "R1",
    point: 5,
  },

  {
    id: 2,
    name: "R2",
    point: 15,
  },
];
function ListRecompenseScrenn({
  reward,
  setReward,
  setIsAddComponent,
}) {
  const classes = useStyles();

  return (
    <div className={classes.list}>
      <List className={classes.root}>
        {dummyData.map((r) => {
          return (
            <>
              <Recompense reward={r} setReward={setReward} setIsAddComponent={setIsAddComponent} />
              <Divider />
            </>
          );
        })}
      </List>
    </div>
  );
}

export default connect(null)(ListRecompenseScrenn);
