import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import AddTodoScreen from "./AddTodoScreen";
import ListTodosScreen from "./ListTodosScreen";

import { connect } from 'react-redux';

//import { loadUser } from "../../../../actions/authActions";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MobileHeader from '../../Header/HeaderMobile'
import Mymodal from "../../../modal/Mymodal";
import MyAddbutton from "../../../buttons/MyAddButton/MyAddbutton";
import ConfirmationModal from '../../../modal/ConfirmationModal';

import { Link } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import moment from "moment";
import NotStartedYet from "../../NotStartedYet";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
const TodosScreen = ({ history, propsAdd = "todoADD", isAddComponent, setIsAddComponent }) => {
    const auth = useSelector((state) => state.auth.user);

    const [todo, setTodo] = useState({
        name: "",
        estimatedDuration: "",
        isCompleted: false,
        todoDate: new Date(),
        weeklyGoal: "",
        comment: "",
        priority: 15,
    })

    //for bakcdrop
    const classes = useStyles();
    const [openBackDrop, setOpenBackdrop] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const handleClose = () => {
        setOpenBackdrop(false);
    };
    const handleToggle = () => {
        setOpenBackdrop(!openBackDrop);
    };

    const today = moment(new Date()).format("DD/MM/YYYY");
    const startChallengeUser = moment(auth.startDayChallenge, "DD/MM/YYYY").toDate();
    const startChallengeUserMoment = moment(startChallengeUser).format("DD/MM/YYYY");

    return (
        <div className="todo__section section">
            <Backdrop className={classes.backdrop} open={openBackDrop} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <MobileHeader title="Tâches journalières"
                right={
                    <div>
                        {/* <Link to="/my-profile">
                            <IconButton>
                                <i className="uil uil-user-circle button__right"></i>
                            </IconButton>
                        </Link> */}
                    </div>}
            />

            <div className="todo__container container grid">
                <MyAddbutton propsAdd={propsAdd} />

                <ConfirmationModal
                    openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
                    confirmationTitle="Suppression d'une tache journalière"
                    confirmationText="Etes-vous sur de vouloir supprimer cette tache?"
                    component="Todo"
                    idToDelete={idToDelete}
                    setOpenBackdrop={setOpenBackdrop}
                />

                <Mymodal className="todo-modal" modalTitle="Tâche journalière"
                    setIsAddComponent={setIsAddComponent}
                    modalContent={
                        <>
                            <AddTodoScreen todo={todo} setTodo={setTodo} openBackDrop={openBackDrop} setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} />
                        </>}
                />

                {/* Check if challenge has already started or not */}
                {moment(startChallengeUserMoment) > moment(today) ? (
                    <>
                        <NotStartedYet />
                    </>
                ) : (
                    <>
                        <ListTodosScreen
                            setTodo={setTodo}
                            openBackDrop={openBackDrop} setOpenBackdrop={setOpenBackdrop}
                            isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent}
                            setOpenConfirmation={setOpenConfirmation}
                            setIdToDelete={setIdToDelete}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default TodosScreen;