import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "../CSS/LoginScreen.css";
import { connect } from 'react-redux';

import { login } from '../../../../actions/authActions';

//import logo
import logoXs from '../../../../img/logoXs.jpg'
import logoLg from '../../../../img/logoLg.png'

//translation
import { useTranslation } from "react-i18next";

const LoginScreen = ({ history, login }) => {
    const auth = useSelector(state => state.auth.user);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState("");

    useEffect(() => {
        // If an authToken alreafy exists in the localstorage, it means that the user is
        // already connected so just redirect him/her to the "/" path
        if (auth.token) {
            history.push("/");
        }

        // fetch all user from airtable       
    }, [auth]);

    const loginHandler = async (e) => {
        e.preventDefault();

        setCredentials({
            email: credentials.email,
            password: credentials.password
        });

        login(credentials);

        setCredentials({
            email: "",
            password: ""
        });
    };

    const { t, i18n } = useTranslation("");

    return (
        <div className="welcome">

            <div className="login__content">
                <form onSubmit={loginHandler} className="login-screen__form">
                    <img src={logoLg} alt="" className="login__img" />
                    <h3 className="login-screen__title">{t("login")}</h3>

                    {error && <span className="error-message">{error}</span>}

                    <div className="form-group">
                        <label htmlFor="email">{t("labels.email")}:</label>
                        <input
                            type="email"
                            required
                            id="email"
                            placeholder={t("placehoder.enterEmail")}
                            value={credentials.email}
                            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                            tabIndex={1}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">
                            {t("labels.password")}:

                        </label>
                        <input
                            type="password"
                            required
                            id="password"
                            autoComplete="true"
                            placeholder={t("placehoder.enterPassword")}
                            value={credentials.password}
                            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                            tabIndex={2}
                        />
                        <Link to="/forgotpassword" className="login-screen__forgotpassword" tabIndex={4}>
                            {t("loginScreen.forgotPassword")}
                        </Link>
                    </div>

                    <button type="submit" className="button button__submit" onSubmit={loginHandler} tabIndex={3}>
                        {t("buttons.signIn")}
                    </button>

                    {/* <span className="login-screen__subtext">
                        Vous avez recu un email de changer de mot de passe ? <Link to="/resetpassword"> Changer votre mot de passe</Link>
                    </span> */}

                    {/*<span className="login-screen__subtext">
                        {t("loginScreen.haveAccount")} <Link to="/register"> {t("register")}</Link>
                    </span>*/}
                </form>

            </div>

        </div>
    );
};

export default connect(null, { login })(LoginScreen);