import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";

// Import screens
import Header from '../src/components/screens/Header/Header'
import LoginScreen from './components/screens/Auth/JS/LoginScreen.js';
import RegisterScreen from './components/screens/Auth/JS/RegisterScreen';
import ForgotPasswordScreen from './components/screens/Auth/JS/ForgotPasswordScreen';
import ResetPasswordScreen from './components/screens/Auth/JS/ResetPasswordScreen';
import TodosScreen from './components/screens/Todo/JS/TodosScreen';
import GoalScreen from './components/screens/Goal/GoalScreen';
import YearlyGoalsScreen from './components/screens/Goal/Yearly/JS/YearlyGoalsScreen';
import TrimesterGoalsScreen from './components/screens/Goal/Trimester/JS/TrimesterGoalsScreen';
import WeeklyGoalsScreen from './components/screens/Goal/Weekly/JS/WeeklyGoalsScreen';
import Profile from './components/screens/profile/Profile'
import RecompenseScreen from './components/screens/Recompenses/RecompenseScreen';
import AstucesScreen from './components/screens/astuces/AstucesScreen';
import RoutinesScreen from './components/screens/Routine/JS/RoutinesScreen';
//dashboard
import Dashboard from '../src/components/screens/dashboard/Dashboard';

//tutopage
import Welcome from './components/screens/welcome/Welcome';
import Finish from './components/screens/Finish';
import moment from "moment";

const App = ({ history }) => {
    const auth = useSelector(state => state.auth.user);
    const stepIntro = useSelector((state) => state.auth.user.stepIntro);
    //trigger if add button is clicked or
    const [isAddComponent, setIsAddComponent] = useState(true);

    const search = window.location.pathname;
    const isRegisterPath = search.includes('/register')
    const isResetPasswordPath = search.includes('/resetpassword')

    const end = auth.endDayChallenge;
    const today = moment(new Date()).format("DD/MM/YYYY");

    return (
        <div className="app">
            <Router>
                <Switch>
                    <Route path="/login" component={LoginScreen} />
                    <Route path="/register">
                        <RegisterScreen />
                    </Route>
                    <Route path="/forgotpassword" >
                        <ForgotPasswordScreen />
                    </Route>
                    <Route path="/resetpassword" >
                        <ResetPasswordScreen />
                    </Route>
                </Switch>

                {!auth.token ? (
                    <>
                        {(isRegisterPath || isResetPasswordPath) ? (
                            <></>
                        ) : (
                            <Redirect to="/login" />
                        )}
                    </>
                ) : (
                    <>
                        {end === today && stepIntro != 0 ? (
                            <>
                                <Switch>
                                    <Route path="/newchallange" >
                                        <Finish />
                                    </Route>
                                </Switch>

                                <Redirect to="/newchallange" />
                            </>
                        ) : (
                            <Switch>
                                <Route path="/tips" >
                                    <Header active="tips" />
                                    <AstucesScreen />
                                </Route>

                                <Route path="/reward" >
                                    <Header active="reward" />
                                    <RecompenseScreen isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                                </Route>

                                <Route path="/my-profile" >
                                    <Header active="film" />
                                    <Profile />
                                </Route>

                                <Route path="/todo" >
                                    <Header active="todo" />
                                    <TodosScreen isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                                </Route>

                                <Route path="/goal" >
                                    <Header active="goal" />
                                    <GoalScreen isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                                </Route>

                                <Route path="/goal/yearly" >
                                    <Header />
                                    <YearlyGoalsScreen />
                                </Route>

                                <Route path="/goal/trimester" >
                                    <Header />
                                    <TrimesterGoalsScreen />
                                </Route>

                                <Route path="/goal/weekly" >
                                    <Header />
                                    <WeeklyGoalsScreen />
                                </Route>

                                <Route path="/dashboard" >
                                    <Header active="db" />
                                    <Dashboard />
                                </Route>

                                <Route path="/hello" >
                                    <Welcome isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                                </Route>

                                <Route path="/routine" >
                                    <Header active="routine" />
                                    <RoutinesScreen isAddComponent={isAddComponent} setIsAddComponent={setIsAddComponent} />
                                </Route>

                                <Route exact path="/" >
                                    {stepIntro === 7 ? <Redirect to="/dashboard" /> : <Redirect to="/hello" />}
                                </Route>
                            </Switch>
                        )}
                    </>
                )}
            </Router>
        </div>
    );
}

export default App;
