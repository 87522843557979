import React, { useState, useEffect, Component } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from "@material-ui/core";
import { Create, Delete, CheckCircle, PlayCircleFilled, StopSharp } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment";

import { connect } from 'react-redux';

import "../CSS/RoutineScreen.css";
import { updateRoutine } from '../../../../actions/routineActions';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CachedIcon from '@material-ui/icons/Cached';
import PauseIcon from '@material-ui/icons/Pause';

const RoutineScreen = ({ routine, setRoutine, updateRoutine, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const routines = useSelector((state) => state.routines);
    const auth = useSelector((state) => state.auth.user);

    const [checked, setChecked] = useState(routine.isCompleted);

    const updateClickHandler = (popupState) => {
        // As we are using the same form for "ADD" method, 
        // setting setRoutine here will auto-prefill the different inputs
        // So that user can change only what he wants
        setRoutine({ ...routine, name: routine.name });
        popupState.close();
        const oneModaltodo = document.querySelector(".modal__views");
        oneModaltodo.classList.add("active-modal");
        setIsAddComponent(false)
    }

    const deleteHandler = (id) => {
        setOpenBackdrop(true)
        //deleteTodo(id);
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);

        const id = routine._id

        const updatedRoutine = {
            name: routine.name,
            isCompleted: event.target.checked,
            comment: routine.comment,
        }

        updateRoutine(updatedRoutine, id);
    };

    return (
        <>
            <Card className="">
                <CardHeader
                    className="cardHeader"
                    action={
                        <PopupState variant="popover" popupId="demo-popup-popover" >
                            {(popupState) => (
                                <div >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                    />
                                    <MoreVertIcon {...bindTrigger(popupState)} />
                                    <Popover className="popover--container"
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box p={2}>
                                            {/* {routine.isCompleted ? (
                                                <Button>
                                                    <CheckCircle className="isCompleted" />
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button onClick={() => updateClickHandler(popupState)}>
                                                        <Create color="primary" />
                                                    </Button>
                                                    <Button onClick={() => { setIdToDelete(routine._id); setOpenConfirmation(true) }}>
                                                        <Delete color="secondary" />
                                                    </Button>
                                                </>
                                            )} */}
                                            <Button onClick={() => updateClickHandler(popupState)}>
                                                <Create color="primary" />
                                            </Button>
                                            <Button onClick={() => { setIdToDelete(routine._id); setOpenConfirmation(true) }}>
                                                <Delete color="secondary" />
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>

                    }
                    title={routine.isCompleted ? (
                        <div>
                            <h3 className="checked qualification__title"> {routine.name}</h3>
                        </div>
                    ) : (
                        < div >
                            <h3 className="qualification__title"> {routine.name}</h3>
                        </div>
                    )}
                    subheader={
                        <div>
                            <div>
                                {/* <span className="inside--card">
                                    <i className="uil uil-hourglass"></i>
                                    Commentaire: {routine.comment}
                                </span> */}

                                {
                                    routine.comment ? (
                                        <span className="inside--card inside__card--note">
                                            <i className="uil uil-notes "></i>
                                            {routine.comment}
                                        </span>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </div>
                    }
                />
            </Card>
        </>
    )
}

export default connect(null, { updateRoutine })(RoutineScreen);