import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all tips and tools from Airtable by user id
export const getAvailableTipsAndToolsByUser = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/tipsandtools/usertipsandtools', setHeaders(token))
        .then(userTipsAndTools => {
            dispatch({
                type: "GET_ALL_TIPS_AND_TOOLS_BY_USER",
                userTipsAndTools
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Get all tips and tools from Airtable
/*export const getAllTipsAndTools = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/tipsandtools/alltipsandtools', setHeaders(token))
        .then(allTipsAndTools => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_ALL_TIPS_AND_TOOLS",
                allTipsAndTools
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}*/

