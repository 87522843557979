export const serviceWorkerDev = async () => {
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    let swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swUrl)
            .then((res) => {
                //console.log("Registering SW... ", res);
            })

        /*await navigator.serviceWorker.ready
            .then(function (registration) {
                console.log("SW ready...");
                return registration.pushManager.getSubscription()
                    .then(function (subscription) {
                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array('BKe1tltA0WcMsgyn7qlDW6mRZp2D5tqgfKELGGmh4v893LoFPURgN4TXQMfOw9goYcHzlgu_6Vxu2MEJTQ27dGE')
                        })
                    })
            })*/
    }

}