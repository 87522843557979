import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

const initialState = {
    user: {
        token: null,
        name: null,
        email: null,
        _id: null,
        startDayChallenge: null,
        endDayChallenge: null,
        trimesters: null,
        dates: null,
        currentTrimester: "",
        currentWeek: "",
        firstTimeLoggedIn: "",
        stepIntro: "",
        level: ""
    }
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN":
        case "REGISTER":
            toast.success("Welcome...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })

            return {
                ...state,
                user: {
                    token: action.user.token,
                    name: action.user.user.name,
                    email: action.user.user.email,
                    _id: action.user.user._id,
                    startDayChallenge: action.user.user.startDayChallenge,
                    endDayChallenge: action.user.user.endDayChallenge,
                    trimesters: action.user.user.trimesters,
                    dates: action.user.user.dates,
                    currentTrimester: action.user.user.currentTrimester,
                    currentWeek: action.user.user.currentWeek,
                    firstTimeLoggedIn: action.user.user.firstTimeLoggedIn,
                    stepIntro: action.user.user.stepIntro,
                    level: action.user.user.level,
                }
            };
        case "LOGOUT":
            return {
                user: {
                    token: null,
                    name: null,
                    email: null,
                    _id: null,
                    startDayChallenge: null,
                    endDayChallenge: null,
                    trimesters: null,
                    dates: null,
                    currentTrimester: "",
                    currentWeek: "",
                    firstTimeLoggedIn: "",
                    stepIntro: "",
                    level: "",
                }
            }
        case "UPDATE_STEP_NUMBER_INTRO":
            return {
                ...state,
                user: {
                    token: action.token,
                    name: action.user.data.user.name,
                    email: action.user.data.user.email,
                    _id: action.user.data.user._id,
                    startDayChallenge: action.user.data.user.startDayChallenge,
                    endDayChallenge: action.user.data.user.endDayChallenge,
                    trimesters: action.user.data.user.trimesters,
                    dates: action.user.data.user.dates,
                    currentTrimester: action.user.data.user.currentTrimester,
                    currentWeek: action.user.data.user.currentWeek,
                    firstTimeLoggedIn: action.user.data.user.firstTimeLoggedIn,
                    stepIntro: action.user.data.user.stepIntro,
                    level: action.user.data.user.level
                }
            }
        case "START_CHALLENGE":
            //return console.log("action eh 2s", action.token);
            return {
                ...state,
                user: {
                    token: action.token,
                    name: action.user.data.user.name,
                    email: action.user.data.user.email,
                    _id: action.user.data.user._id,
                    startDayChallenge: action.user.data.user.startDayChallenge,
                    endDayChallenge: action.user.data.user.endDayChallenge,
                    trimesters: action.user.data.user.trimesters,
                    dates: action.user.data.user.dates,
                    currentTrimester: action.user.data.user.currentTrimester,
                    currentWeek: action.user.data.user.currentWeek,
                    firstTimeLoggedIn: action.user.data.user.firstTimeLoggedIn,
                    stepIntro: action.user.data.user.stepIntro,
                    level: action.user.data.user.level,
                }
            }
        case "RESTART_CHALLENGE":
            return {
                ...state,
                user: {
                    token: action.token,
                    name: action.user.data.user.name,
                    email: action.user.data.user.email,
                    _id: action.user.data.user._id,
                    startDayChallenge: action.user.data.user.startDayChallenge,
                    endDayChallenge: action.user.data.user.endDayChallenge,
                    trimesters: action.user.data.user.trimesters,
                    dates: action.user.data.user.dates,
                    currentTrimester: action.user.data.user.currentTrimester,
                    currentWeek: action.user.data.user.currentWeek,
                    firstTimeLoggedIn: action.user.data.user.firstTimeLoggedIn,
                    stepIntro: action.user.data.user.stepIntro,
                    level: action.user.data.user.level,
                }
            }
        case "RESTART_AFTER_FINISH_CHALLENGE":
            return {
                ...state,
                user: {
                    token: action.token,
                    name: action.user.data.user.name,
                    email: action.user.data.user.email,
                    _id: action.user.data.user._id,
                    startDayChallenge: action.user.data.user.startDayChallenge,
                    endDayChallenge: action.user.data.user.endDayChallenge,
                    trimesters: action.user.data.user.trimesters,
                    dates: action.user.data.user.dates,
                    currentTrimester: action.user.data.user.currentTrimester,
                    currentWeek: action.user.data.user.currentWeek,
                    firstTimeLoggedIn: action.user.data.user.firstTimeLoggedIn,
                    stepIntro: action.user.data.user.stepIntro,
                    level: action.user.data.user.level,
                }
            }
        default:
            return state;
    }
}

export default authReducer;