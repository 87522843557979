import { toast } from 'react-toastify';

const routineReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_ROUTINES":
            return action.routines.data;
        case "ADD_ROUTINE":
            toast.success("New task successfully added...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return [action.routine.data, ...state];
        case "UPDATE_ROUTINE":
            toast.success("Task successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((routine) => routine._id === action.routine.data._id ? action.routine.data : routine
            )
        case "UPDATE_ALL_ROUTINES":
            toast.success("Task successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return action.routines.data
        case "DELETE_ROUTINE":
            toast.success("Task successfully deleted...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.filter((routine) => routine._id !== action.id
            )
        default:
            return state
    }
}

export default routineReducer;