import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Button, TextField } from "@material-ui/core";
import { Send } from '@material-ui/icons';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//inputs
import AddYG from "../Goal/Yearly/JS/AddYearlyGoalScreen";
import AddTG from "../Goal/Trimester/JS/AddTrimesterGoalScreen";
import AddWG from "../Goal/Weekly/JS/AddWeeklyGoalScreen";
import AddTODO from "../Todo/JS/AddTodoScreen";

//stepper
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";

import "./Finish.css";

// Date picker
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import { startChallenge, numberStepIntro } from "../../../actions/authActions";
import { addYearlyGoal } from '../../../actions/yearlyGoalActions'

//icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//translation
import { useTranslation } from "react-i18next";

//action
import { restartAfterFinishChallenge } from "../../../actions/authActions";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Finish({ restartAfterFinishChallenge }) {
    const [openBackDrop, setOpenBackdrop] = useState(false);

    const { t, i18n } = useTranslation("");
    const classes = useStyles();

    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
    };

    const auth = useSelector((state) => state.auth.user);

    const restartAfterFinishChallengeHandler = () => {
        restartAfterFinishChallenge();
    }

    return (
        <div className="welcome">

            <Backdrop className={classes.backdrop} open={openBackDrop} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={classes.root}>
                <div>
                    <h2 className="section__title">Félicitations !</h2>
                    <br></br>
                    <span className="section__subtitle">
                        <p>
                            Et voilà ! Tu es allé jusqu'au bout de ton challenge et je te félicite ! Vraiment, BRAVO !
                        </p><br></br>
                        <p>
                            Ce que je t'invite à faire maintenant, c’est de faire l’analyse suivante:
                        </p><br></br><br></br>
                        <p><b>1. Mesurer l’écart entre ce que tu as réalisé est-ce que tu avais prévu de réaliser.</b></p>
                        <p><b>2. Quel est ton ressenti ?</b></p>
                        <p><b>3. Comment expliques-tu cet écart ou cette réussite ? </b></p><p>(Ex: Trop ambitieux, trop d’objectifs, des perturbations extérieures..)</p>
                        <p><b>4. Que dois-tu changer ? </b></p><p>(Ex: Se fixer moins d’objectifs, Se fixer des objectifs qui dépendent uniquement de moi,…)</p>
                        <p><b>5.Note tes nouveaux objectifs en tenant compte de tes observations</b></p><br></br><br></br>
                        <p>
                            Tu verras, tu vas pouvoir encore t'améliorer et exploser tous tes objectifs sur le prochain challenge à venir
                        </p><br></br>
                        <p>
                            Amicalement :)
                        </p><br></br>
                        <p>
                            Jimmy
                        </p>
                    </span>

                    <div className="btn--intro">
                        <Button
                            className={classes.backButton}
                            variant="contained"
                            color="primary"
                            onClick={restartAfterFinishChallengeHandler}
                        >
                            Démarrer mon nouveau challenge
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default connect(null, { restartAfterFinishChallenge })(Finish);
