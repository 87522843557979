import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, List } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ShowMoreText from "react-show-more-text";
import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    openItem: {
        backgroundColor: "White",
        border: "2px solid #e5e5e5",
        borderRadius: "5px",
    },
    lastItem: {
        backgroundColor: "#e5e5e5"
    }
}));

function Astuce({ tips, last }) {
    const [isClicked, setIsClicked] = useState(false);
    const updateClickHandler = (popupState) => {
        // As we are using the same form as "ADD" method,
        // setting setReward here will auto-prefill the different inputs
        // So that user can change only what he wants
        //setReward({ ...tips });
        popupState.close();
        const oneModaltodo = document.querySelector(".modal__views");
        oneModaltodo.classList.add("active-modal");
        //setIsAddComponent(false);
    };

    //listen when button done is clicked <></>
    //useEffect(() => {}, [isClicked]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsClicked(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [isClicked]);

    const [expand, setExpand] = useState(false);

    const onClick = () => {
        setExpand(!expand);
    };

    const classes = useStyles();
    useEffect(() => {

    }, [expand]);
    return (
        <>
            <List className={classes.root}>
                <ListItem key={tips.id} role={undefined} className={clsx(classes.openItem, {
                    [classes.lastItem]: last,
                })} >
                    <ListItemText
                        id={tips.id}
                        primary={tips.fields.titre}
                        secondary={<>
                            <ShowMoreText
                                lines={2}
                                more={last ? "" : "Voir plus"}
                                less={"Voir moins"}
                                onClick={onClick}
                                expanded={expand}
                                width={500}
                            >
                                {tips.fields.message}
                            </ShowMoreText>
                        </>}
                    />
                    <ListItemSecondaryAction>

                        {/* <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expand,
                        })}
                        aria-label="show more"
                        onClick={() => onClick()}
                    >
                        <ExpandMoreIcon />
                    </IconButton> */}
                        <IconButton
                            edge="end"
                            aria-label="comments"
                        >
                            {last ? <LockIcon className={isClicked ? "isCompleted" : ""} /> :
                                <a href={tips.fields.lien} target="_blanck"><LinkIcon className={isClicked ? "isCompleted" : ""} /></a>
                                /*<Link
                                    to={tips.fields.lien}
                                    target="_blank"
                                >
                                    <LinkIcon className={isClicked ? "isCompleted" : ""} />
                                </Link>*/
                            }

                        </IconButton>

                    </ListItemSecondaryAction>
                    {/* <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => setIsClicked(true)}
                    >
                        <DoneAllIcon className={isClicked ? "isCompleted" : ""} />
                    </IconButton>

                    <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                            <>
                                <IconButton edge="end" aria-label="comments">
                                    <MoreVertIcon {...bindTrigger(popupState)} />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <Box>
                                        <Button>
                                            <EditIcon
                                                onClick={() => updateClickHandler(popupState)}
                                            />
                                        </Button>
                                        <Button>
                                            <DeleteIcon className="notActive" />
                                        </Button>
                                    </Box>
                                </Popover>
                            </>
                        )}
                    </PopupState>
                </ListItemSecondaryAction> */}
                </ListItem>
                <Divider />
            </List>
        </>
    );
}

export default Astuce;
