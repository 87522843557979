import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all routines
export const getRoutines = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/routine/allroutines', setHeaders(token))
        .then(routines => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_ROUTINES",
                routines
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Create a new todo
export const addRoutine = (routine) => (dispatch, getState) => {
    const uid = getState().auth.user._id;
    const token = getState().auth.user.token

    axios.post('/api/routine/createroutine', { ...routine, uid }, setHeaders(token))
        .then(routine => {
            dispatch({
                type: "ADD_ROUTINE",
                routine
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update a routine
export const updateRoutine = (updateRoutine, id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/routine/updateroutine/${id}`, updateRoutine, setHeaders(token))
        .then(routine => {
            dispatch({
                type: "UPDATE_ROUTINE",
                routine
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update a routine
export const updateAllRoutines = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/routine/updateallroutines`, setHeaders(token))
        .then(routines => {
            dispatch({
                type: "UPDATE_ALL_ROUTINES",
                routines
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Delete a routine
export const deleteRoutine = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.delete(`/api/routine/removeroutine/${id}`, setHeaders(token))
        .then(() => {
            dispatch({
                type: "DELETE_ROUTINE",
                id
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

