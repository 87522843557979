import { toast } from 'react-toastify';

const trimesterGoalReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_TRIMESTER_GOALS":
            return action.goals.data;
        case "GET_TRIMESTER_GOALS_WITH_YEARLYGOALID":
            return action.goals.data;
        case "ADD_TRIMESTER_GOAL":
            toast.success("New trimester goal successfully added...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
                return [action.goal.data, ...state];
        case "UPDATE_TRIMESTER_GOAL":
            toast.success("Trimester goal successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((goal) => goal._id === action.goal.data._id ? action.goal.data : goal
            )
        case "DELETE_TRIMESTER_GOAL":
            toast.success("Trimester goal successfully deleted...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.filter((goal) => goal._id !== action.id
            )
        default:
            return state
    }
}

export default trimesterGoalReducer;