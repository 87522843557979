import { toast } from 'react-toastify';

const yearlyGoalReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_YEARLY_GOALS":
            return action.goals.data;
        case "GET_YEARLY_GOALS_WITH_ID":
            return action.goals.data;
        case "ADD_YEARLY_GOAL":
            toast.success("New yearly goal successfully added...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return [action.goal.data, ...state];
        case "UPDATE_YEARLY_GOAL":
            toast.success("Yearly goal successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((goal) => goal._id === action.goal.data._id ? action.goal.data : goal
            )
        case "DELETE_YEARLY_GOAL":
            toast.success("Yearly goal successfully deleted...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.filter((goal) => goal._id !== action.id)
        default:
            return state
    }
}

export default yearlyGoalReducer;