import { toast } from 'react-toastify';

const weeklyGoalReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_WEEKLY_GOALS":
            return action.goals.data;
        case "GET_WEEKLY_GOALS_WITH_TRIMESTERGOALID":
            return action.goals.data;
        case "ADD_WEEKLY_GOAL":
            toast.success("New weekly goal successfully added...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return [action.goal.data, ...state];
        case "UPDATE_WEEKLY_GOAL":
            toast.success("Weekly goal successfully updated...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.map((goal) => goal._id === action.goal.data._id ? action.goal.data : goal
            )
        case "DELETE_WEEKLY_GOAL":
            toast.success("Weekly goal successfully deleted...", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return state.filter((goal) => goal._id !== action.id
            )
        default:
            return state
    }
}

export default weeklyGoalReducer;