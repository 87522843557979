import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

function Recompense({ reward, setReward, setIsAddComponent }) {
  const [isClicked, setIsClicked] = useState(false);
  const updateClickHandler = (popupState) => {
    // As we are using the same form as "ADD" method,
    // setting setReward here will auto-prefill the different inputs
    // So that user can change only what he wants
    setReward({ ...reward });
    popupState.close();
    const oneModaltodo = document.querySelector(".modal__views");
    oneModaltodo.classList.add("active-modal");
    setIsAddComponent(false);
  };

  //listen when button done is clicked
  //useEffect(() => {}, [isClicked]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsClicked(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isClicked]);

  return (
    <>
      <ListItem key={reward.id} role={undefined} button>
        <ListItemText
          id={reward.id}
          primary={reward.name}
          secondary={reward.point}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => setIsClicked(true)}
          >
            <DoneAllIcon className={isClicked ? "isCompleted" : ""} />
          </IconButton>

          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <>
                <IconButton edge="end" aria-label="comments">
                  <MoreVertIcon {...bindTrigger(popupState)} />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box>
                    <Button>
                      <EditIcon
                        onClick={() => updateClickHandler(popupState)}
                      />
                    </Button>
                    <Button>
                      <DeleteIcon className="notActive" />
                    </Button>
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default Recompense;
