import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Astuce from "./Astuce";
import { useDispatch, useSelector } from "react-redux";
import MobileHeader from '../Header/HeaderMobile'
import ListAstuceScreen from "./ListAstuceScreen";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAvailableTipsAndToolsByUser } from "../../../actions/tipsToolsActions";
import moment from "moment";

import NotStartedYet from "../NotStartedYet";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    display: "flex",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function AstucesScreen({ getAvailableTipsAndToolsByUser }) {
  const auth = useSelector((state) => state.auth.user);
  const astuces = useSelector((state) => state.tipsTools.userTipsAndTools);
  const classes = useStyles();
  const [openBackDrop, setOpenBackdrop] = useState(false);

  const today = moment(new Date()).format("DD/MM/YYYY");
  const startChallengeUser = moment(auth.startDayChallenge, "DD/MM/YYYY").toDate();
  const startChallengeUserMoment = moment(startChallengeUser).format("DD/MM/YYYY");

  return (
    <section className="section">
      <Backdrop className={classes.backdrop} open={openBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MobileHeader title="Astuces et Outils"
        right={
          <div>
            {/* <Link to="/my-profile">
                            <IconButton>
                                <i className="uil uil-user-circle button__right"></i>
                            </IconButton>
                        </Link> */}
          </div>}
      />
      {/* Check if challenge has already started or not */}
      {moment(startChallengeUserMoment) > moment(today) ? (
        <>
          <NotStartedYet />
        </>
      ) : (
        <>
          <div className={classes.list}>
            <ListAstuceScreen openBackDrop={openBackDrop} setOpenBackdrop={setOpenBackdrop} />
          </div>
        </>
      )}
    </section>
  );
}

export default connect(null, { getAvailableTipsAndToolsByUser })(AstucesScreen);
