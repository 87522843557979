import { toast } from 'react-toastify';

const todoTodayReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_TODOS_STATUS_TODAY":
            return action.todosToday.data;
        default:
            return state
    }
}

export default todoTodayReducer;