import React, { useState, useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, ButtonGroup, Button, TextField } from "@material-ui/core";
import { Create, Delete, CheckCircle, Send, StopSharp } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Warning';
import moment from "moment";

import "../CSS/YearlyGoalScreen.css";

import { connect } from 'react-redux';

import { updateYearlyGoal, deleteYearlyGoal } from '../../../../../actions/yearlyGoalActions'
import { getTrimesterGoalsWithYGoalID, updateTrimesterGoal, deleteTrimesterGoal } from '../../../../../actions/trimesterGoalActions'
import { getWeeklyGoalsWithTGoalID, updateWeeklyGoal, deleteWeeklyGoal } from '../../../../../actions/weeklyGoalActions'

//card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CachedIcon from '@material-ui/icons/Cached';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

import Tooltip from '@material-ui/core/Tooltip';


import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        color: red[500],
        transform: 'rotate(0deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));


const YearlyGoalScreen = ({
    yearlyGoal, setYearlyGoal, updateYearlyGoal, deleteYearlyGoal,
    getTrimesterGoalsWithYGoalID, updateTrimesterGoal, deleteTrimesterGoal,
    getWeeklyGoalsWithTGoalID, updateWeeklyGoal, deleteWeeklyGoal,
    setIsUpdate, setOpenBackdrop, setIsAddComponent,
    setOpenConfirmation, setIdToDelete
}) => {

    const dispatch = useDispatch();
    const yearlyGoals = useSelector((state) => state.yearlyGoal);
    const trimesterGoals = useSelector((state) => state.trimesterGoal);
    const weeklyGoals = useSelector((state) => state.weeklyGoal);
    const [realisedYearGoal, setRealisedYearGoal] = useState({
        ...yearlyGoal,
        realNumberedGoal: yearlyGoal.realNumberedGoal
    });
    const [noteYearlyGoal, setNoteYearGoal] = useState({
        ...yearlyGoal,
        note: yearlyGoal.note
    })

    useEffect(() => {

    }, [yearlyGoals, trimesterGoals, weeklyGoals])

    const updateClickHandler = (popupState) => {
        // As we are using the same form as "ADD" method, 
        // setting setYearlyGoal here will auto-prefill the different inputs
        // So that user can change only what he wants
        setYearlyGoal({ ...yearlyGoal });

        popupState.close();
        const oneModaltodo = document.querySelector(".modal__views");
        oneModaltodo.classList.add("active-modal");
        setIsAddComponent(false)
    }

    const realisedYearGoalHandler = () => {
        if (yearlyGoal._id) {
            setOpenBackdrop(true);
            const id = yearlyGoal._id

            setYearlyGoal({
                ...yearlyGoal,
                realNumberedGoal: realisedYearGoal.realNumberedGoal
            })

            const updatedRealisedYearlyGoal = {
                ...yearlyGoal,
                realNumberedGoal: realisedYearGoal.realNumberedGoal,
            }

            updateYearlyGoal(updatedRealisedYearlyGoal, id, 1);
            setExpanded(false);
        }
    }

    const noteYearGoalHandler = () => {
        if (yearlyGoal._id) {
            setOpenBackdrop(true);
            const id = yearlyGoal._id

            setYearlyGoal({
                ...yearlyGoal,
                note: noteYearlyGoal.note
            })

            const updatedNoteYearlyGoal = {
                ...yearlyGoal,
                note: noteYearlyGoal.note,
            }

            updateYearlyGoal(updatedNoteYearlyGoal, id, 1);

            setYearlyGoal({
                ...yearlyGoal,
                note: ""
            })
            setExpanded(false);
        }
    }

    const deleteHandler = (id) => {
        setOpenBackdrop(true);
        deleteYearlyGoal(id, 1);
    }

    const stopHandler = () => {
        if (yearlyGoal._id) {
            setOpenBackdrop(true);
            const id = yearlyGoal._id

            setYearlyGoal({
                ...yearlyGoal,
                active: false
            })

            const updatedActiveYearlyGoal = {
                ...yearlyGoal,
                active: false,
            }

            updateYearlyGoal(updatedActiveYearlyGoal, id, 1);

            setYearlyGoal({
                ...yearlyGoal,
                active: true
            })
        }
    }

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Card className="my__oneCard" style={{ backgroundColor: yearlyGoal.color }}>
                <CardHeader
                    className="cardHeader"
                    avatar={
                        yearlyGoal.isCompleted ? (
                            <CheckCircle className="isCompleted" />

                        ) : !yearlyGoal.active ? (

                            <CancelIcon className="notActive" />

                        )
                            : !yearlyGoal.deleteBtn ? (
                                <CachedIcon className="isRunning" />
                            ) : (
                                <RadioButtonUncheckedIcon className="action inside--card-icon" />
                            )
                    }
                    action={
                        <>
                            <PopupState variant="popover" popupId="demo-popup-popover" >
                                {(popupState) => (
                                    <div >
                                        <MoreVertIcon {...bindTrigger(popupState)} className="inside--card-icon" />
                                        <Popover className="popover--container"
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box p={1}>
                                                <div>
                                                    {yearlyGoal.isCompleted ? (
                                                        <Button>
                                                            <CheckCircle className="isCompleted" />
                                                        </Button>
                                                    ) : !yearlyGoal.active ? (
                                                        <CancelIcon className="notActive" />
                                                    ) : yearlyGoal.deleteBtn ? (
                                                        <>
                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(false) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => { setIdToDelete(yearlyGoal._id); setOpenConfirmation(true) }}>
                                                                <Delete className="isActive" />
                                                            </Button>
                                                            {/* <Button onClick={() => deleteHandler(yearlyGoal._id)}>
                                                                <Delete className="isActive" />
                                                            </Button> */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button onClick={() => { updateClickHandler(popupState); setIsUpdate(true) }}>
                                                                <Create color="primary" />
                                                            </Button>
                                                            <Button onClick={() => stopHandler()}>
                                                                <StopSharp className="isActive" />
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>

                                            </Box>
                                        </Popover>
                                    </div>
                                )}
                            </PopupState>
                        </>
                    }
                    title={
                        <div>
                            <h3 className="qualification__title">  {yearlyGoal.estimatedNumberedGoal} {" "} {yearlyGoal.name}</h3>
                            {yearlyGoal.comment ? (
                                <div className="">
                                    {yearlyGoal.comment}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    }
                    subheader={
                        <div>
                            <div className="goal__card-content ">
                                <div>
                                    <span className="inside--card">
                                        <i className="uil uil-process"></i>
                                        Achevé: {yearlyGoal.percentageAchieved}%

                                    </span>

                                    <span className="inside--card">
                                        <i className="uil uil-calender"></i>
                                        Ajouté: {moment(yearlyGoal.createdAt).fromNow()}
                                    </span>
                                    {
                                        yearlyGoal.note ? (
                                            <span className="inside--card inside__card--note">
                                                <i className="uil uil-notes "></i>
                                                {yearlyGoal.note}
                                            </span>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                <div className="">
                                    <IconButton
                                        className={clsx(classes.expand, {
                                            [classes.expandOpen]: expanded,
                                        })}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        {yearlyGoal.isCompleted ? (
                                            <div></div>
                                        ) : !yearlyGoal.active ? (
                                            <NoteAddIcon className="isCompleted" />
                                        ) : yearlyGoal.deleteBtn ? (
                                            <AssignmentTurnedInIcon className="isCompleted" />
                                        ) : (
                                            <>
                                                <AssignmentTurnedInIcon className="isCompleted" />
                                            </>
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit >
                    <CardContent className="cardContent">
                        <div className="goal__card-input">
                            {yearlyGoal.active ? (
                                <>
                                    {!yearlyGoal.isCompleted &&
                                        <>
                                            <div className="inside--card-right">

                                                <TextField
                                                    id="realisedNumber"
                                                    label={`Réalisé / ${yearlyGoal.estimatedNumberedGoal}`}
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    step="1"
                                                    value={realisedYearGoal.realNumberedGoal}
                                                    onChange={(e) => setRealisedYearGoal({ ...realisedYearGoal, realNumberedGoal: Math.round(e.target.value) })}
                                                    required
                                                />


                                                <Button onClick={() => realisedYearGoalHandler()}>
                                                    <Send color="primary" />
                                                </Button>
                                            </div>
                                        </>
                                    }
                                </>
                            ) : (
                                <>
                                    <div className="inside--card-right">
                                        <TextField
                                            id="note"
                                            label="Pourquoi avez-vous stoppé cet objectif?"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            style={{ width: "100%" }}
                                            value={noteYearlyGoal.note}
                                            onChange={(e) => setNoteYearGoal({ ...noteYearlyGoal, note: e.target.value })}

                                            required
                                        />
                                        <Button onClick={() => noteYearGoalHandler()}>
                                            <Send color="primary" />
                                        </Button>
                                    </div>
                                </>
                            )}


                        </div>
                    </CardContent>
                </Collapse>
                <Box p={1}> <div className="inside--card-date">
                    Dernière modification : {moment(yearlyGoal.updatedAt).fromNow()}
                </div>
                </Box>

            </Card>

        </>
    )
}

export default connect(null, { updateYearlyGoal, deleteYearlyGoal, getTrimesterGoalsWithYGoalID, updateTrimesterGoal, deleteTrimesterGoal, getWeeklyGoalsWithTGoalID, updateWeeklyGoal, deleteWeeklyGoal })(YearlyGoalScreen);