import React, { useEffect } from "react";
//jquery
import $ from "jquery";

function Mymodal({ modalTitle, modalContent, otherClass, setIsAddComponent }) {
  useEffect(() => {
    //for modal control
    const modalViews = document.querySelectorAll(".modal__views"),
      modalBtns = document.querySelectorAll(".modal__button"),
      modalCloses = document.querySelectorAll(".modal__close");

    let modal = function (modalClick) {
      modalViews[modalClick].classList.add("active-modal");
    };

    modalBtns.forEach((modalBtn, i) => {
      modalBtn.addEventListener("click", () => {
        modal(i);
      });
    });
    modalCloses.forEach((modalClose, i) => {
      modalClose.addEventListener("click", () => {
        modalViews.forEach((modalView) => {
          $("input").each(function (index) {
            $(this).val("");
          });
          modalView.classList.remove("active-modal");
          setIsAddComponent(true);
        });
      });
    });

    //close modal on click outside th content
    modalViews.forEach((modalView) => {
      modalView.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) {
          //alert("child clicked");
        } else {
          //alert("parent clicked");
          //const allInputs = document.querySelectorAll("input");

          $("input").each(function (index) {
            $(this).val("");
          });
          modalView.classList.remove("active-modal");
          setIsAddComponent(true);
        }
      });
    });
  }, []);

  return (
    <div className={`services__modal modal__views ${otherClass} `}>
      <div className="services__modal-content">
        <h4 className="services__modal-title"> {modalTitle} </h4>
        <i className="uil uil-times services__modal-close  modal__close"></i>
        {modalContent}
      </div>
    </div>
  );
}

export default Mymodal;
