import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./css/HeaderMobile.css";
import { useHistory } from "react-router-dom";

function HeaderMobile({ title, right }) {
  let history = useHistory();
  return (
    <div className="mobile__top" id="modbile__top">
      <IconButton onClick={() => history.goBack()}>
        <ArrowBackIosIcon className="button__back " />
      </IconButton>
      <h1 className="mobile__title">{title}</h1>
      <div>{right}</div>
    </div>
  );
}

export default HeaderMobile;
