import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import AddYearlyGoalScreen from "./AddYearlyGoalScreen";
import ListYearlyGoalScreen from "./ListYearlyGoalScreen";

//modal
import Mymodal from "../../../../modal/Mymodal";
import MyAddbutton from "../../../../buttons/MyAddButton/MyAddbutton";
import ConfirmationModal from '../../../../modal/ConfirmationModal';
import IconButton from '@material-ui/core/IconButton';

const YearlyGoalsScreen = ({ history, propsAdd = "goalADD", setOpenBackdrop, isAddComponent, setIsAddComponent }) => {
    const auth = useSelector((state) => state.auth.user);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");

    const [yearlyGoal, setYearlyGoal] = useState({
        type: 1,
        estimatedNumberedGoal: 0,
        name: "",
        realNumberedGoal: 0,
        note: "",
        percentageAchieved: 0,
        active: true,
        deleteBtn: true,
        comment: "",
        color: "",
    })

    //modal
    const [isUptate, setIsUpdate] = useState(false);

    return (
        <>
            <MyAddbutton propsAdd={propsAdd} />

            <ConfirmationModal
                openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}
                confirmationTitle="Suppression d'un objectif annuel"
                confirmationText="En supprimant cet objectif annuel, vous allez également supprimer tous les objectifs trimestriels et hebdomadaires liés à celui-ci.
                                    Etes-vous sur de vouloir supprimer cet objectif?"
                component="Yearly Goal"
                idToDelete={idToDelete}
                setOpenBackdrop={setOpenBackdrop}
            />

            <Mymodal className="goal--modal" modalTitle="Objectif annuel " setIsAddComponent={setIsAddComponent}
                modalContent={
                    <>
                        <AddYearlyGoalScreen yearlyGoal={yearlyGoal} setYearlyGoal={setYearlyGoal} isUptate={isUptate} setIsUpdate={setIsUpdate} locationComponent="goalScreen" setOpenBackdrop={setOpenBackdrop} isAddComponent={isAddComponent} />
                    </>}
            />

            <ListYearlyGoalScreen
                setYearlyGoal={setYearlyGoal}
                isUptate={isUptate} setIsUpdate={setIsUpdate}
                setOpenBackdrop={setOpenBackdrop}
                setIsAddComponent={setIsAddComponent}
                setOpenConfirmation={setOpenConfirmation}
                setIdToDelete={setIdToDelete}
            />
        </>
    );
};

export default YearlyGoalsScreen;