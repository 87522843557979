import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from "@material-ui/core";
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { connect } from 'react-redux';

import YearlyGoalScreen from "./YearlyGoalScreen";
import "../CSS/ListYearlyGoalScreen.css";
import { getYearlyGoals } from '../../../../../actions/yearlyGoalActions';


const ListYearlyGoalScreen = ({ setYearlyGoal, getYearlyGoals, isUptate, setIsUpdate, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const dispacth = useDispatch();
    const yearlyGoals = useSelector((state) => state.yearlyGoal.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1));

    useEffect(() => {
        getYearlyGoals();
    }, []);

    useEffect(() => {
        setOpenBackdrop(false);
    }, [yearlyGoals])

    useEffect(() => {
        setOpenBackdrop(true);
    }, [])

    return (
        <>
            <div className=" grid">
                <h4>
                    {yearlyGoals.length > 0 ? "Vos objectifs annuels" : "Aucun objectif annuel"}{" "}

                </h4>

                <div className="todo__list">
                    {yearlyGoals && yearlyGoals.map((yearlyGoal) => {
                        return (
                            <YearlyGoalScreen
                                yearlyGoal={yearlyGoal}
                                key={yearlyGoal._id}
                                setYearlyGoal={setYearlyGoal}
                                setIsUpdate={setIsUpdate}
                                setOpenBackdrop={setOpenBackdrop}
                                setIsAddComponent={setIsAddComponent}
                                setOpenConfirmation={setOpenConfirmation}
                                setIdToDelete={setIdToDelete}
                            />
                        );
                    })}
                </div>

            </div>
        </>
    )
}

export default connect(null, { getYearlyGoals })(ListYearlyGoalScreen);