import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from "@material-ui/core";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import TrimesterGoalScreen from "./TrimesterGoalScreen";
import "../CSS/ListTrimesterGoalScreen.css";
import { getTrimesterGoals } from '../../../../../actions/trimesterGoalActions';

import { connect } from 'react-redux';

//
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const ListTrimesterGoalScreen = ({ setTrimesterGoal, getTrimesterGoals, isUptate, setIsUpdate, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete }) => {
    const dispacth = useDispatch();
    const yearlyGoals = useSelector(state => state.yearlyGoal);
    const trimesterGoals = useSelector((state) => state.trimesterGoal.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1));
    const trimesterDates = JSON.parse(localStorage.getItem("dates"));
    const [trimesterName, setTrimesterName] = useState(
        [
            { value: "t1", name: "Trimestre 1" },
            { value: "t2", name: "Trimestre 2" },
            { value: "t3", name: "Trimestre 3" },
            { value: "t4", name: "Trimestre 4" }
        ])

    useEffect(() => {
        getTrimesterGoals();
    }, [dispacth])

    useEffect(() => {
        setOpenBackdrop(false);
    }, [trimesterGoals])

    useEffect(() => {
        setOpenBackdrop(true);
    }, [])


    const classes = useStyles();
    return (
        <>
            <div className="grid">
                <h4>

                    {trimesterGoals.length > 0 ? "Vos objectifs trimestriels" : "Aucun objectif trimestriel"}{" "}
                </h4>

                <div className={classes.root}>
                    {trimesterName && trimesterName.map(tn => {

                        return (
                            <Accordion className="accordion">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>{tn.name} <br /> </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="inside--accordion">
                                    {trimesterGoals && trimesterGoals.map((trimesterGoal) => {
                                        return (
                                            <>
                                                {yearlyGoals && yearlyGoals.map(yearlyGoal => {
                                                    return (
                                                        <>
                                                            {(yearlyGoal._id == trimesterGoal.yearlyGoalID && tn.value == trimesterGoal.trimester) ?
                                                                (<>
                                                                    <TrimesterGoalScreen
                                                                        trimesterGoal={trimesterGoal}
                                                                        key={trimesterGoal._id}
                                                                        setTrimesterGoal={setTrimesterGoal}
                                                                        yearlyGoal={yearlyGoal}
                                                                        setIsUpdate={setIsUpdate}
                                                                        setOpenBackdrop={setOpenBackdrop}
                                                                        setIsAddComponent={setIsAddComponent}
                                                                        setOpenConfirmation={setOpenConfirmation}
                                                                        setIdToDelete={setIdToDelete}
                                                                    />
                                                                </>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>

                <div className="my__card">
                    {trimesterName && trimesterName.map(tn => {
                        return (
                            <>
                                <h4>{tn.name}</h4>
                                {trimesterGoals && trimesterGoals.map((trimesterGoal) => {
                                    return (
                                        <>
                                            {yearlyGoals && yearlyGoals.map(yearlyGoal => {
                                                return (
                                                    <>
                                                        {yearlyGoal._id == trimesterGoal.yearlyGoalID ?
                                                            <>
                                                                {tn.value == trimesterGoal.trimester ?
                                                                    <>
                                                                        <TrimesterGoalScreen
                                                                            trimesterGoal={trimesterGoal}
                                                                            key={trimesterGoal._id}
                                                                            setTrimesterGoal={setTrimesterGoal}
                                                                            yearlyGoal={yearlyGoal}
                                                                            setIsAddComponent={setIsAddComponent}
                                                                            setOpenConfirmation={setOpenConfirmation}
                                                                            setIdToDelete={setIdToDelete}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                        }
                                                    </>
                                                )

                                            })}

                                        </>
                                    );
                                })}

                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default connect(null, { getTrimesterGoals })(ListTrimesterGoalScreen);