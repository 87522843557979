import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from "@material-ui/core";
import moment from 'moment';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { connect } from 'react-redux';


import WeeklyGoalScreen from "./WeeklyGoalScreen";
import "../CSS/ListWeeklyGoalScreen.css";

import { getTrimesterGoals } from '../../../../../actions/trimesterGoalActions';
import { getWeeklyGoals } from '../../../../../actions/weeklyGoalActions';
import authReducer from '../../../../../reducers/authReducers';

//Accordion
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


const ListWeeklyGoalScreen = ({ setWeeklyGoal, getTrimesterGoals, getWeeklyGoals, isUptate, setIsUpdate, setOpenBackdrop, setIsAddComponent, setOpenConfirmation, setIdToDelete, trimester }) => {
    const auth = useSelector(state => state.auth.user);
    const yearlyGoals = useSelector(state => state.yearlyGoal);
    const trimesterGoals = useSelector(state => state.trimesterGoal);
    const weeklyGoals = useSelector((state) => state.weeklyGoal.sort((a, b) => (a.trimesterGoalID < b.trimesterGoalID) ? 1 : -1));
    const weeklyDates = JSON.parse(localStorage.getItem("dates"));
    const [weeklyName, setWeeklyName] = useState([])

    const getWeekDates = () => {
        let nbWeeks;
        let valueWeek;
        let nameWeek;
        let objWeek;

        for (let x = 0; x < auth.trimesters.t1.weeks.length; x++) {
            objWeek = {
                trimester: "t1",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t1.weeks[x].startDay,
                startDayFormat: moment(new Date(auth.trimesters.t1.weeks[x].startDay)).format('DD/MM/YYYY'),
                endDay: auth.trimesters.t1.weeks[x].endDay,
                endtDayFormat: moment(new Date(auth.trimesters.t1.weeks[x].endDay)).format('DD/MM/YYYY'),

            }

            weeklyName.push(objWeek);

        }

        for (let x = 0; x < auth.trimesters.t2.weeks.length; x++) {
            objWeek = {
                trimester: "t2",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t2.weeks[x].startDay,
                startDayFormat: moment(new Date(auth.trimesters.t2.weeks[x].startDay)).format('DD/MM/YYYY'),
                endDay: auth.trimesters.t2.weeks[x].endDay,
                endtDayFormat: moment(new Date(auth.trimesters.t2.weeks[x].endDay)).format('DD/MM/YYYY'),

            }

            weeklyName.push(objWeek);
        }

        for (let x = 0; x < auth.trimesters.t3.weeks.length; x++) {
            objWeek = {
                trimester: "t3",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t3.weeks[x].startDay,
                startDayFormat: moment(new Date(auth.trimesters.t3.weeks[x].startDay)).format('DD/MM/YYYY'),
                endDay: auth.trimesters.t3.weeks[x].endDay,
                endtDayFormat: moment(new Date(auth.trimesters.t3.weeks[x].endDay)).format('DD/MM/YYYY'),

            }

            weeklyName.push(objWeek);
        }

        for (let x = 0; x < auth.trimesters.t4.weeks.length; x++) {
            objWeek = {
                trimester: "t4",
                value: "w" + (x + 1),
                name: "Semaine " + (x + 1),
                startDay: auth.trimesters.t4.weeks[x].startDay,
                startDayFormat: moment(new Date(auth.trimesters.t4.weeks[x].startDay)).format('DD/MM/YYYY'),
                endDay: auth.trimesters.t4.weeks[x].endDay,
                endtDayFormat: moment(new Date(auth.trimesters.t4.weeks[x].endDay)).format('DD/MM/YYYY'),

            }

            weeklyName.push(objWeek);
        }

    }

    useEffect(() => {
        getTrimesterGoals();
        getWeeklyGoals();
        getWeekDates();
    }, [weeklyName])

    useEffect(() => {
        setOpenBackdrop(false);
    }, [weeklyGoals])

    useEffect(() => {
        setOpenBackdrop(true);
    }, [])

    const classes = useStyles();
    return (
        <div>
            <div className="grid">
                <h4>
                    {" "}
                    {weeklyGoals.length > 0 ? "Vos objectifs hebdomadaires" : "Aucun objectf hebdomadaire"}{" "}
                </h4>
                <div className={classes.root} >
                    {weeklyName && weeklyName.map(wn => {
                        return (
                            <div >
                                <div className={wn.trimester == trimester ? "sameTrimester" : "notSameTrimester"}>
                                    <Accordion className="accordion">
                                        <AccordionSummary

                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>{wn.name}: <br /> {wn.startDay} au {wn.endDay}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="inside--accordion">
                                            {weeklyGoals && weeklyGoals
                                                .filter((w) => wn.value == w.week)
                                                .map((weeklyGoal) => {
                                                    return (
                                                        <div key={weeklyGoal._id}>
                                                            {trimesterGoals && trimesterGoals
                                                                //.filter((t) => (t._id == weeklyGoal.trimesterGoalID && t.trimester == auth.currentTrimester))
                                                                .filter((t) => (t._id == weeklyGoal.trimesterGoalID && t.trimester == trimester))
                                                                .map(trimesterGoal => {
                                                                    return (
                                                                        <div key={trimesterGoals._id}>
                                                                            <WeeklyGoalScreen
                                                                                weeklyGoal={weeklyGoal}
                                                                                key={weeklyGoal._id}
                                                                                setWeeklyGoal={setWeeklyGoal}
                                                                                trimesterGoal={trimesterGoal}
                                                                                setIsUpdate={setIsUpdate}
                                                                                setOpenBackdrop={setOpenBackdrop}
                                                                                setIsAddComponent={setIsAddComponent}
                                                                                setOpenConfirmation={setOpenConfirmation}
                                                                                setIdToDelete={setIdToDelete}
                                                                                trimester={trimester}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                        </div>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default connect(null, { getTrimesterGoals, getWeeklyGoals })(ListWeeklyGoalScreen);