import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all Trimester goals
export const getTrimesterGoals = () => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get('/api/goal/alltrimestergoals', setHeaders(token))
        .then(goals => {
            dispatch({
                type: "GET_TRIMESTER_GOALS",
                goals
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}

// Get all Trimester goals with specific yearlyGoalID
export const getTrimesterGoalsWithYGoalID = (id) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/goal/alltrimestergoals/${id}`, setHeaders(token))
        .then(goals => {
            //console.log("TodoActions: ", todos);
            dispatch({
                type: "GET_TRIMESTER_GOALS_WITH_YEARLYGOALID",
                goals
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Create a new Trimester
export const addTrimesterGoal = (trimesterGoal, type) => (dispatch, getState) => {
    const uid = getState().auth.user._id;
    const token = getState().auth.user.token

    axios.post(`/api/goal/creategoal/${type}`, { ...trimesterGoal, uid }, setHeaders(token))
        .then(goal => {
            dispatch({
                type: "ADD_TRIMESTER_GOAL",
                goal
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Update a Trimester goal
export const updateTrimesterGoal = (updatedTrimesterGoal, id, type) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.put(`/api/goal/updategoal/${type}/${id}`, updatedTrimesterGoal, setHeaders(token))
        .then(goal => {
            //console.log("TodoActions: ", todo);
            dispatch({
                type: "UPDATE_TRIMESTER_GOAL",
                goal
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


// Delete a todo
export const deleteTrimesterGoal = (id, type) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.delete(`/api/goal/deletegoal/${type}/${id}`, setHeaders(token))
        .then(() => {
            dispatch({
                type: "DELETE_TRIMESTER_GOAL",
                id
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}


