import axios from 'axios';
import { toast } from 'react-toastify';
import { setHeaders } from '../api/headers';

// Get all todos with params
export const getTodosStatusToday = (status) => (dispatch, getState) => {
    const token = getState().auth.user.token
    axios.get(`/api/todo/alltodosstatustoday/${status}`, setHeaders(token))
        .then(todosToday => {
            dispatch({
                type: "GET_TODOS_STATUS_TODAY",
                todosToday
            })
        })
        .catch(error => {
            //console.log("Error in /store/todoActions: ", error.response);
            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTON_RIGHT
            });
        })
}



