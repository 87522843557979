import { toast } from 'react-toastify';

const tipsToolsReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_ALL_TIPS_AND_TOOLS":
            return action.allTipsAndTools.data;
        case "GET_ALL_TIPS_AND_TOOLS_BY_USER":
            return action.userTipsAndTools.data;
        default:
            return state
    }
}

export default tipsToolsReducer;